<template lang="pug">
  header.header_wrapper
    a.astra_burger(href='#' v-on:click='toggleMenu')
      img(src='~@/assets/astra-burger.png' alt='')
    router-link.astra_logo(to='/personal-cabinet/my-loans')
      img(src='~@/assets/astra-logo.png' alt='')
    .header_right_block
      a.logout(@click='logout' href='#')
        span.logout_text Выход
        img.logout_icon(src='~@/assets/astra-logout.svg' alt='Выход')
    //- a.astra_man(href='#')
    //-   img(src='~@/assets/astra-man.png' alt='')

</template>

<script>
export default {
  name: "Header",
  methods: {
    menuon() {
      this.$store.commit("menuon");
    },
    toggleMenu() {
      this.$store.commit("toggle");
    },
    logout() {
      let { dispatch } = this.$store;
      dispatch("authentication/logout");
    }
  }
};
</script>

<style scoped>
.header_wrapper {
  background-color: transparent;
  max-width: 1440px;
  height: 100px;
  margin: auto;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.header_wrapper .header_right_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_wrapper .logout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-right: 84px; */
}

.header_wrapper .logout span {
  margin-right: 25px;
}

.header_wrapper .change_language {
  font-family: "Segoe Ui", Helvetica, Arial, sans-serif;
  color: #ef7f19;
  font-size: 16px;
  font-weight: bold;
}

.astra_burger,
.astra_man {
  display: none;
}

@media only screen and (max-width: 700px) {
  .header_wrapper {
    width: 100%;
    height: inherit;
    padding: 20px;
  }

  .astra_burger,
  .astra_man {
    display: inline-block;
  }

  .header_wrapper .header_right_block {
    display: none;
  }

   .header_wrapper .astra_logo {
     width: unset;
   }

  .header_wrapper .astra_logo img {
    width: 145px;
  }


}
</style>
