<template lang="pug">
  .personal_cabinet_wrapper
    Header
    section.main_wrapper
      NavBar(v-bind:class='{ active: this.$store.getters.IS_MOBILE_MENU }')
      router-view.main_block.personal_cabinet(v-bind:class='{ active: !this.$store.getters.IS_MOBILE_MENU }')
    footer.footer_wrapper
</template>


<script>
import Header from "./Header.vue";
import NavBar from "./NavBar.vue";
// import axios from "axios";

export default {
  name: "App",
  data() {
    return {
    };
  },
  computed: {
    // profile() {
    //   console.log('hello from pc component where profile => ', this.$store.state.users.profile.item)
    //   return this.$store.state.users.profile
    // }
  },
  components: {
    Header,
    NavBar
  },
  created() {
    this.$store.dispatch('users/getProfile');
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/my_loans.scss";
</style>