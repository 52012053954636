<template lang="pug">
div
  h2.nar_title История транзакций
  .loans_block
    template(v-if="transactions")
      div(v-if="transactions.length > 0")
        .loan_item_link(  v-for="transaction in transactions", :key="transaction.id" )
          TrnansactionInfo(:transaction="transaction")
      div(v-else-if="transactions.length===0")
        .no_loans
          h1 Нет данных
    template(v-else)
      Skeleton
</template>

<script>
import TrnansactionInfo from "./TrnansactionInfo.vue";
import Skeleton from "@/components/Common/Skeleton";

export default {
  name: "Transachistory",
  components: { TrnansactionInfo, Skeleton },
  computed: {
    transactions() {
      return this.$store.getters["loans_module/TRANSACTIONS"];
    },
  },
  created: function () {
    this.$store.dispatch("loans_module/getAllTransactions");
  },
};
</script>

