<template lang="pug">
  .change_password_wrapper
    h2 Мои данные
    .content_wrapper.row(v-if='profile')
      .form_block.column
        .astra_input_wrapper
          label.label_text(for='current_password') Фамилия
          input.astra-input(type='text' disabled  v-model='profile.last_name')
          //- input.astra-input(type='text' disabled placeholder='Введите Вашу фамилию' v-model='profile.last_name')
        .astra_input_wrapper
          label.label_text(for='new_password') Имя 
          input.astra-input(type='text' disabled  v-model='profile.first_name')
          //- input.astra-input(type='text' disabled placeholder='Введите Ваше имя' v-model='profile.first_name')
        .astra_input_wrapper
          label.label_text(for='confirm_password') Отчество
          input.astra-input(type='text' disabled  v-model='profile.middle_name')
          //- input.astra-input(type='text' disabled placeholder='Введите Ваше отчество' v-model='profile.middle_name')
      .form_block.column
        .astra_input_wrapper
          label.label_text(for='current_password') E-Mail
          input.astra-input(type='text' disabled  v-model='profile.email')
          //- input.astra-input(type='text' disabled placeholder='Введите Ваш E-Mail' v-model='profile.email')
        .astra_input_wrapper
          label.label_text(for='new_password') Телефон
          input.astra-input(type='text' disabled  v-model='profile.mobile')
          //- input.astra-input(type='text' disabled placeholder='Введите Ваш телефон' v-model='profile.mobile')
        .astra_input_wrapper
          label.label_text(for='confirm_password') Дата рождения
          input.astra-input(type='text' disabled   v-model='profile.birth_date')
          //- input.astra-input(type='text' disabled placeholder='Дата рождения' v-model='profile.birth_date')
    //- .form_buttons_block
    //-   button.astra_accept_button(@click='changeUser') Изменить

</template>

<script>
export default {
  name: "MyData",
  data() {
    return {};
  },
  computed: {
    profile() {
      return this.$store.state.users.profile;
    }
  },
  methods: {
    // changeUser() {
    //   let profile = this.profile;
    //   console.log("component - changeUser - ", this.profile);
    //   let { dispatch } = this.$store;
    //   dispatch("users/changeUser", profile);
    // }
  },
  created() {
    // this.$store.dispatch("users/getProfile");
  }
};
</script>

<style scoped>
.form_buttons_block {
  padding: 0 30px;
}
@media only screen and (max-width: 700px) {
  .content_wrapper.row {
    display: block;
  }

  .astra_input_wrapper input {
    background-color: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);
  }
}
</style>
