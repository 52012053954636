<template>
  <div class="atachment_item">
    <div class="atachment_item_header">
      <span class="atachment_item_index">{{index+1}}.</span>
      <span class="atachment_item_type">
        <template v-if="atachment.name.trim() != 'авто'">
          {{atachment.name}}
          <template v-if="atachment.note">({{atachment.note}})</template>
        </template>
        <template v-else>
          {{atachment.data.auto_brand}}
          {{atachment.data.auto_model}}
        </template>
      </span>
      <span class="atachment_prop_value"></span>
    </div>

    <div class="atachment_item_info_block">
      <!-- <div class="atachment_item_info_peace">
        <div class="atachment_prop_text">Название:</div>
        <div class="atachment_prop_value">{{atachment.name}}</div>
      </div>-->

      <div v-if="category.trim().toLowerCase() === 'золото'" class="atachment_item_info_peace">
        <div class="atachment_prop_text">Количество:</div>
        <div class="atachment_prop_value">{{atachment.quantity}}</div>
      </div>

      <div
        v-if="atachment.note && category.trim().toLowerCase() !='золото'"
        class="atachment_item_info_peace"
      >
        <div class="atachment_prop_text">Примечание:</div>
        <div class="atachment_prop_value">{{atachment.note }}</div>
      </div>

      <template v-for="(  item, key) in dict">
        <div
          v-if="item.show && atachment.data[key] || (category.trim().toLowerCase() === 'золото' && key === 'hand_amount')"
          :key="item.name"
          class="atachment_item_info_peace"
        >
          <div class="atachment_prop_text">{{item.name }}:</div>
          <div v-if="typeof atachment.data[key] === 'boolean'">
            <div v-if="atachment.data[key]" class="atachment_prop_value">да</div>
            <div v-else-if="!atachment.data[key]" class="atachment_prop_value">нет</div>
          </div>
          <div v-else class="atachment_prop_value">{{atachment.data[key]}}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "AtachmentDetails",
  props: {
    atachment: Object,
    index: Number,
    category: String,
  },
  data: () => ({
    dict: {
      auto_vin: { name: "Вин", show: true },
      auto_year: { name: "Год", show: true },
      auto_brand: { name: "Марка", show: false },
      auto_model: { name: "Модель", show: false },
      auto_number: { name: "Номер", show: true },
      gram_price: { name: "Сумма за грамм", show: false },
      sample: { name: "Проба", show: true },
      weight: { name: "Вес с камнем", show: true },
      weight_without_stone: { name: "Вес без камня", show: true },
      paid_for: { name: "Уплачено", show: false },
      hand_amount: { name: "Сумма", show: false },
    },
  }),
    mounted() {
    console.log('AtachmentDetails atachment => ', this.atachment, this.index, this.category)
  },
};
</script>

<style>
.atachment_item {
  /* width: 50%; */
  margin: 20px 0;
}

.atachment_item + .atachment_item {
  margin-top: 50px;
}

.atachment_item_info_left_side {
  margin-right: 13%;
  margin-left: 12px;
}

.atachment_item_info_block {
  margin: 25px;
  display: grid;
  /* grid-template-rows: repeat(2, 1fr); */
  grid-row-gap: 20px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.atachment_prop_text {
  color: #707070;
  font-size: 12px;
  margin-bottom: 7px;
}

.atachment_prop_value {
  color: #343a8b;
  font-weight: 500;
  font-size: 14px;
}

.atachment_item_type {
  color: #343a8b;
  font-size: 18px;
  font-weight: 600;
}

.atachment_item_index {
  color: #343a8b;
  font-size: 18px;
  font-weight: 700;
  margin-right: 6px;
}

.atachment_item_info_peace {
  /* margin: 18px 10px; */
}

@media only screen and (max-width: 700px) {
  .atachment_item {
    width: unset;
  }
}
</style>
