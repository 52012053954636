<template>
  <div class="left_side_wrapper">
    <div class="greeting_block">
      Добро пожаловать,
      <span class="username_text">{{profile.first_name}}</span>
    </div>
    <div class="navigation_menu">
      <router-link class="menu_item" to="/personal-cabinet/my-loans" v-on:click.native="menuoff">
        <img
          class="menu_item_icon"
          src="~@/assets/personal_cabinet-menu/astra-menu-my_loans.svg"
          alt
        />
        <span class="menu_item_text">Мои займы</span>
        <img class="astra_right_arrow" src="~@/assets/right-arrow.png" alt />
      </router-link>

      <router-link
        class="menu_item"
        to="/personal-cabinet/transac-history"
        v-on:click.native="menuoff"
      >
        <img
          class="menu_item_icon"
          src="~@/assets/personal_cabinet-menu/astra-menu-transac_history.svg"
          alt
        />
        <span class="menu_item_text">История транзакций</span>
        <img class="astra_right_arrow" src="~@/assets/right-arrow.png" alt />
      </router-link>

      <router-link class="menu_item" to="/personal-cabinet/my-data" v-on:click.native="menuoff">
        <img
          class="menu_item_icon"
          src="~@/assets/personal_cabinet-menu/astra-menu-my_data.svg"
          alt
        />
        <span class="menu_item_text">Мои данные</span>
        <img class="astra_right_arrow" src="~@/assets/right-arrow.png" alt />
      </router-link>

      <router-link
        class="menu_item"
        to="/personal-cabinet/change-password"
        v-on:click.native="menuoff"
      >
        <img
          class="menu_item_icon"
          src="~@/assets/personal_cabinet-menu/astra-menu-change_password.svg"
          alt
        />
        <span class="menu_item_text">Сменить пароль</span>
        <img class="astra_right_arrow" src="~@/assets/right-arrow.png" alt />
      </router-link>

      <a
        @click="logout(); menuoff()"
        class="menu_item exit"
      >
        <img
          class="menu_item_icon"
          src="~@/assets/personal_cabinet-menu/astra-menu-change_password.svg"
          alt
        />
        <span class="menu_item_text">Выйти</span>
        <img class="astra_right_arrow" src="~@/assets/right-arrow.png" alt />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      //   username: this.$store.getters.USERNAME
    };
  },
  computed: {
    profile() {
      return this.$store.state.users.profile;
    },
  },
  methods: {
    menuoff() {
      this.$store.commit("menuoff");
    },
    logout() {
      let { dispatch } = this.$store;
      dispatch("authentication/logout");
    }
  },
};
</script>

<style scoped>
.left_side_wrapper {
  color: #343a8b;
  font-size: 16px;
  font-family: "Segoe UI";
}

.left_side_wrapper,
.greeting_block {
  padding: 20px 0;
}

.greeting_block {
  text-align: left;
}

.greeting_block .username_text {
  display: block;
  font-size: 52px;
  font-weight: 600;
}
.navigation_menu .menu_item {
  display: flex;
  background-color: transparent;
  text-align: left;
  border-radius: 14px;
  min-width: 336px;
  padding: 3px 0;
  margin: 15px 0;
  align-items: center;
  transition: background-color 0.3s ease;
}

.navigation_menu .menu_item:hover,
.navigation_menu .menu_item.router-link-active {
  background-color: #efeff8;
}

.navigation_menu .menu_item .menu_item_icon {
  margin-right: 32px;
}

.astra_right_arrow {
  display: none;
}

.menu_item.exit {
  display: none;
}

@media only screen and (max-width: 1366px) {
  .navigation_menu .menu_item {
    min-width: 285px;
  }
}

@media only screen and (max-width: 1024px) {
  .navigation_menu {
    display: flex;
    justify-content: space-between;
  }

  .navigation_menu .menu_item {
    min-width: 24%;
    width: 24%;
  }

  .navigation_menu .menu_item .menu_item_icon {
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 700px) {
  .menu_item.exit {
    display: flex;
  }

  .navigation_menu {
    display: block;
  }

  .greeting_block {
    padding: 20px 22px;
  }

  .left_side_wrapper {
    width: 100%;
  }

  .navigation_menu .menu_item {
    background-color: #ffffff;
    min-width: unset;
    width: 100%;
    padding: 14px 22px;
    margin: 15px 0;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }

  .navigation_menu .menu_item .menu_item_icon {
    display: none;
  }

  .astra_right_arrow {
    display: inline;
  }
}
</style>
