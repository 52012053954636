<template lang="pug">
    skeleton-loading(, style="padding: 10px;")
      row(v-for="i in count", :key="i", :gutter="{ top: '10px', bottom: '10px' }")
        column(:span="3", :gutter="10")
          square-skeleton(
            :boxProperties="{ bottom: '15px', width: '100%', height: '100px' }"
          )
        column.right_block(:span="20", :gutter="10")
          square-skeleton(
            :count="2",
            :boxProperties="{ bottom: '15px', width: '100%', height: '40px' }"
          )
</template>

<script>
    export default {
      props: {
        count: {
          type: Number,
          default: 4
        }
      }
        
    }
</script>

<style lang="scss" scoped>
.right_block .square-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>