<template lang="pug">
div
  h2.nar_title Мои займы
  .loan_switcher_block
    .loan_switcher(
      v-bind:class="{ active: show_active_loans }",
      @click="show_active_loans = true"
    ) Активные
    .loan_switcher(
      v-bind:class="{ active: !show_active_loans }",
      @click="show_active_loans = false"
    ) Закрытые
  div(style="clear:both")
  .loans_block
    template(v-if="loans")
      div(v-if="loans.length > 0")
        .loan_item_link(v-for="loan in loans", :key="loan.id")
          LoanInfo(:loan="loan", caller_name="list")
      div(v-else-if="loans.length === 0")
        .no_loans
          h1 Нет данных
    template(v-else)
      Skeleton
</template>

<script>
import LoanInfo from "./LoanInfo.vue";
import Skeleton from "@/components/Common/Skeleton";

export default {
  name: "MyLoans",
  components: {
    LoanInfo,
    Skeleton,
  },
  data() {
    return {
      show_active_loans: true,
    };
  },
  computed: {
    loans() {
      if (this.show_active_loans)
        return this.$store.getters["loans_module/ACTIVE_LOANS"];
      else return this.$store.getters["loans_module/PASSIVE_LOANS"];
    },
  },
  methods: {},
  beforeCreate() {
    this.$store.state.loans_module.all = {};
    this.$store.state.loans_module.loan = null;
  },
  created: function () {
    this.$store.dispatch("loans_module/getAll");
  },
};
</script>

<style lang="scss">
.no_loans {
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20%;
}
</style>