/* eslint no-undef: 0 */

import { store } from '../store'
import { router } from '../router.js'

console.log('WIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIDGET')

export default function (payinfo) {
    console.log("PAYINFO PAYBOX", payinfo);
    // var widget = new window.cp.CloudPayments();
    // widget.charge(
    //     payinfo,
    //     function (options) {
    //         console.log(options)
    //         router.push('/personal-cabinet/transac-history')
    //     },
    //     function (reason, options) {
    //         console.log('fail', reason, options);
    //         store.state.payError = reason
    //     }
    // );

    payinfo["successCallback"] = function (payment) {
        console.log(payment);
        router.push('/personal-cabinet/transac-history');
    };
    payinfo["errorCallback"] = function (payment) {
        console.log('fail', payment);
        store.state.payError = payment.order;
    };
    var paybox = new PayBox(payinfo);
    paybox.create();
}