<template lang="pug">
  .loan_item(v-if="loan")
    .loan_item_col.transaction_info
      .loan_item_info
        .loan_item_info_label Сумма оплаты
        .loan_item_info_value {{transaction.amount }} &nbsp;
            span.currency_type_text тг

      .loan_item_info
        .loan_item_info_label Дата оплаты:
        .loan_item_info_value {{ transaction.created_at | filterDate}}

    .loan_item_col.desktop
      .loan_item_info
        .loan_item_info_label Залоговый билет №:
        .loan_item_info_value {{loan.key}}

      .loan_item_info
        .loan_item_info_label Филиал:
        .loan_item_info_value {{loan.office}}

    .loan_item_col.desktop.d1150
      .loan_item_info
        .loan_item_info_label Дата открытия:
        .loan_item_info_value {{ loan.loan_date | filterDate}}

      .loan_item_info
        .loan_item_info_label Дата возврата:
        .loan_item_info_value {{loan.return_date | filterDate }}


    .loan_item_right_side.nr_desktop.nr_flex
      .loan_item_right_side_col
        .loan_item_right_side_label Сумма займа:
        .loan_item_right_side_value.blue_text {{(+loan.hand_amount).toLocaleString()}} 
          span.currency_type_text тг
      //- .loan_item_right_side_col
      //-   .loan_item_right_side_label Сумма оплаты:
      //-   .loan_item_right_side_value.orange_text {{(+transaction.amount).toLocaleString()}} 
      //-      span.currency_type_text тг


    .loan_item_right_side.nr_mobile.nr_flex
      .loan_item_right_side_col
        .loan_item_right_side_label Сумма займа:
        .loan_item_right_side_value.blue_text {{(+loan.hand_amount).toLocaleString()}} 
          span.currency_type_text тг

</template>

 <script>
import moment from "moment";

export default {
  name: "LoanInfo",
  props: {
    transaction: Object
  },
  computed: {
    loan() {
      return this.transaction && this.transaction.ticket;
    }
  },
  methods: {
    datediff(first, second) {
      return Math.round(
        (new Date(second) - new Date(first)) / (1000 * 60 * 60 * 24)
      );
    }
  },
  filters: {
    filterDate(value) {
      return moment(value).format("DD.MM.yyyy");
    }
  },
  created() {}
};
</script>

 <style lang='scss'>
.loan_item {
  // height: 160px;
  .loan_item_right_side {
    padding: 10px 0px;
  }
  .loan_item_right_side.nr_mobile {
    display: none;
  }

  .loan_item_col.transaction_info {
    // border: 1px solid #d6d6d6;
    border: 1px solid #ffc625;
    border-radius: 10px;
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 1150px) {
  .loan_item .loan_item_col.d1150 {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .loan_item {
    .loan_item_card {
      margin-right: 0;
    }

    .loan_item_right_side.nr_desktop {
      display: none;
    }

    .loan_item_right_side.nr_mobile {
      display: flex;
      width: 150px;

      .loan_item_loan_block {
        display: block;
      }
    }
  }
}
</style>
