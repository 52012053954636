<template lang="pug">
.loan_item(:class="{ details: caller_name === 'details' || true }")
  .loan_item_card(v-if="loan.closing_date")
    .days_left_text Срок займа
    .days_left_value {{ loan.closing_loan_days }}
  .loan_item_card(
    v-else-if="loan.return_date",
    v-bind:class="{ red: datediff(new Date(), loan.return_date) < 4 }"
  )
    .days_left_text(v-if="loan.return_loan_days >= 0") Осталось дней
    .days_left_text(v-else) Просрочено дней
    .days_left_value {{ Math.abs(loan.return_loan_days) }}

  .loan_item_col.desktop
    .loan_item_info
      .loan_item_info_label Залоговый билет №:
      .loan_item_info_value {{ loan.key }}

    .loan_item_info
      .loan_item_info_label Филиал, сотрудник:
      .loan_item_info_value {{ loan.office + ', ' + loan.merchant }}

  .loan_item_col.desktop.d1150
    .loan_item_info
      .loan_item_info_label Дата открытия:
      .loan_item_info_value {{ loan.loan_date | filterDate }}

    .loan_item_info
      .loan_item_info_label {{ loan.closing_date ? 'Дата закрытия' : 'Дата возврата' }}
      .loan_item_info_value {{ loan.return_date || loan.closing_date | filterDate }}

  .loan_item_right_side.nr_flex
    .loan_item_right_side_col
      .loan_item_right_side_label Сумма займа:
      .loan_item_right_side_value(
        :class="{ blue_text: caller_name === 'details', orange_text: caller_name === 'list' }"
      ) {{ (+loan.hand_amount).toLocaleString() }}
        span.currency_type_text &nbsp;тг

    .loan_item_right_side_col(v-if="(!loan.paid)")
      .loan_item_right_side_label Комиссия:
      //.loan_item_right_side_value.blue_text {{ (Math.floor(+loan.amount*(1/.975-1)*100)/100).toLocaleString() }}
      .loan_item_right_side_value.blue_text {{ (+getCommission(loan.amount)) }}
        span.currency_type_text &nbsp;тг

    .loan_item_right_side_col(v-if="(!loan.paid)")
      .loan_item_right_side_label Сумма к выплате:
      .loan_item_right_side_value.orange_text {{ (+loan.amount).toLocaleString() }}
        span.currency_type_text &nbsp;тг
        
    .form_buttons_block(v-if="!loan.paid")
      router-link.astra_accept_button(
        v-if="caller_name == 'list'",
        :to="{ name: 'LoanDetails', params: { key: loan.key } }"
      ) Подробнее
      button.astra_accept_button.loading(
        v-else-if="caller_name == 'details' && +loan.amount > 0 && isValidLoan(loan.loan_date) && !isValidPayInfo() && !hasServerError()",
      ) Загружается...
      button.astra_accept_button(
        v-else-if="caller_name == 'details' && +loan.amount > 0 && isValidLoan(loan.loan_date) && isValidPayInfo() && !hasServerError()",
        @click="$emit('callPay')"
      ) Продлить
      //- button.astra_accept_button(
      //-   v-if="caller_name == 'list' && +loan.amount > 0 && isValidLoan(loan.loan_date)",
      //-   @click="$emit('callPay')"
      //- ) Продлить
      div(v-else) 
    .loan_item_is_paid_text(v-else="")
      router-link.astra_accept_button(
        v-if="caller_name == 'list'",
        :to="{ name: 'LoanDetails', params: { key: loan.key } }"
      ) Подробнее
</template>

 <script>
import moment from "moment";
// import pay from "@/services/pays.js";

export default {
  name: "LoanInfo",
  data: () => {
    return {
      // caller_name: 'details'
    }
  },
  props: {
    loanId: String,
    loan_key: String,
    loan: Object,
    caller_name: String,
    is_auto: Boolean,
  },
  computed: {
    payInfo() {
      return this.$store.getters["loans_module/PAY_INFO"];
    },
    isServerError() {
      return this.$store.getters["loans_module/IS_SERVER_ERROR"];
    },
  },
  // computed: {
  //   payInfo() {
  //     console.log("payInfo ", this.$store.getters["loans_module/PAY_INFO"]);
  //     return this.$store.getters["loans_module/PAY_INFO"];
  //   },
  // },
  methods: {
    datediff(first, second) {
      return Math.round(
        (new Date(second) - new Date(first)) / (1000 * 60 * 60 * 24)
      );
    },
    isValidLoan(date) {
      return Date.parse(date)>Date.parse('2020-10-05');
    },
    isValidPayInfo () {
      console.log("IS VALID PAY INFO", this.payInfo, this.loan.key);
      return this.payInfo && this.payInfo.payment.order == this.loan.key;
    },
    hasServerError () {
      console.log("IS VALID PAY INFO", this.isServerError);
      return this.isServerError;
    },
    getCommission (amount) {
      var commission = Math.floor(amount*(1/.975-1)*100)/100;
      return commission > 30 || commission == 0 ? commission.toLocaleString() : "30";
      // return Math.floor(amount*(1/.975-1)*100)/100;
    }
  },
  filters: {
    filterDate(value) {
      let a = value.split("T")[0];
      return moment(a).format("DD.MM.yyyy");
    },
  },
  created() {},
  mounted() {
    console.log('LOANINFO loan => ', this.loan);
  },
};
</script>
