<template lang="pug">
  .loan_full_info_wrapper(v-if='loan')
    router-link.loan_navigation_block( to='/personal-cabinet/my-loans')
      .loan_nav_topside(style="justify-content: space-between")
        .menu_item(style="display: flex; align-items: center")
          img.back_to_loans_icon(src='~@/assets/left-arrow.png' alt='')
          span.loan_header(title="Номер залогового билета") Займ {{loan.key}} 
        //- span(style="margin-left: 2rem; color: #ff9393;") {{$store.state.payError}}
      .loan_haeder_dop_info Подробнее о займе
    .loan_mini_info_wrapper
      LoanInfo(:loan='loan' caller_name='details' :is_auto="isAuto" @callPay="callw")
    .loan_dop_info_wrapper
      .atachments_wrapper(v-if='loan.items')
        LoanAtachmentDetails.atachment_item(v-for='(atachment, index) in loan.items' :key='atachment.name + index' :atachment='atachment' :category="loan.category" :index='index')
  .loan_full_info_wrapper(v-else)
    Skeleton(:count="1")
</template>

<script>
import LoanAtachmentDetails from "./LoanAtachmentDetails.vue";
import LoanInfo from "./LoanInfo.vue";
import Skeleton from "@/components/Common/Skeleton";
import pay from "@/services/pays.js";

export default {
  name: "LoanDetails",
  components: { LoanAtachmentDetails, LoanInfo, Skeleton },
  data() {
    return {
      key: "",
      isTransactionShow: false,
    };
  },
  computed: {
    loan_common_sum() {
      if (this.loan.atachments)
        return this.loan.atachments.reduce((a, b) => +a + +b.price_paid, 0);
      else return 0;
    },
    paid_common_sum() {
      if (this.loan.transactions)
        return this.loan.transactions.reduce((a, b) => +a + +b.price, 0);
      else return 0;
    },
    loan() {
      return this.$store.getters["loans_module/LOAN"];
    },
    isAuto() {
      return false && this.loan.category === "Автомобиль";
    },
    payInfo() {
      return this.$store.getters["loans_module/PAY_INFO"];
    },
    isServerError() {
      return this.$store.getters["loans_module/IS_SERVER_ERROR"];
    },
  },
  created: async function () {
    console.log("LOANDETAILS created");

    this.key = this.$route.params.key;

    console.log("LOANDETAILS created => before getByKey");
    await this.$store.dispatch("loans_module/getByKey", this.key);
    console.log("LOANDETAILS created => after getByKey", this.loan);

    console.log(
      "created => before getPayInfo",
      this.payInfo,
      !this.loan.closing_date
    );
    if (this.loan && !this.loan.closing_date) {
      await this.$store.dispatch("loans_module/getPayInfo", this.key);
    }
    console.log(
      "created => after getPayInfo",
      this.payInfo,
      !this.loan.closing_date
    );

    this.$store.state.payError = "";
  },
  mounted: async function () {
    // console.log("mounted loandetails");
    // if (this.loan && !this.loan.closing_date) {
    //   await this.$store.dispatch("loans_module/getPayInfo", this.key);
    // }
  },
  methods: {
    PrevPage() {
      this.$router.go(-1);
    },
    callw() {
      // let current_hour = new Date().getHours();
      // if (current_hour >= 23 || current_hour < 8) {
      //   this.$store.commit("notes_module/showNote", {"name":'pay_time_error', "message":""});
      //   return;
      // }
      let pays = this.payInfo;
      console.log("CALLW =>", pays);
      pay(pays, this.$store.state.users.profile);
    },
  },
};
</script> 


<style lang='scss'>
@import url("~@/assets/scss/loan_details.scss");
</style>